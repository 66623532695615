<template>
  <div class="Video" ref="vlbox">
    <div class="index_main" ref="index_main">
      <aside v-show="hide == 1">
        <div class="title">
          <p>
            <img src="../assets/imgs/zzjg.png" alt="" />
            组织架构
          </p>
        </div>
        <div class="search">
          <button class="btn_red" v-if="permissoin.includes('video')" @click="playChecked">播放选中</button>
        </div>

        <div class="company scrollbar_width0" ref="company_main">
          <el-tree
          ref='Tree'
            :data="treeData"
            show-checkbox
            node-key='id'
            :props="defaultProps">
           <span  slot-scope="{ node}">
        <span v-if='node.childNodes.length==0' style="font-size:14px"><img src="../assets/imgs/sxt.png" alt=""> {{ node.label }}</span>
        <span v-else style="font-size:14px">{{ node.label }}</span>
        <!-- <span  style="font-size:14px">{{ aa(node) }}</span> -->
      </span>
          </el-tree>
        </div>
        <div class="bottom">
          <div>
            <p>
              <span class="green_c"></span>
              项目总数
            </p>
            <p>{{ form.projectCount }}</p>
          </div>
          <div>
            <p>
              <span class="orange_c"></span>
              视频在线/全部
            </p>
            <p>{{ form.onlineCount }}</p>
          </div>
        </div>
      </aside>
              <!-- <div>
           <img
          src="../assets/imgs/btn_left.png"
          alt=""
          v-if="hide == 1"
          @click="hideChange(0)"
          class="hide"
        />
        <img
          src="../assets/imgs/btn_right.png"
          alt=""
          v-if="hide == 0"
          @click="hideChange(1)"
          class="hide"
        />
        </div> -->
        <div class="hk" ref='hk'>
           <div class="title">
          <p class="blue">
            <i class="el-icon-info"></i>您可以点击
            <span class="weight">鼠标右键</span>
            开启云台控制，对视频画面进行缩小、放大、旋转操作。
            <span style="font-weight:600;margin-left:156px">{{orgName}}</span>
          </p>
          <!-- <span @click="close">
            <i class="el-icon-close"></i>
          </span> -->
        </div>
          <div id="playWnd" class="playWnd"></div>
        </div>
    </div>

  </div>
</template>

<script>
import { WebControl } from "../../public/static/web-control.esm.min";
import "echarts-liquidfill";
import { timestampToTime1 } from "@/utils/getTime.js";
import { getTotal, orgAndVideo } from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: { state: 0 },
      pjForm: {},
      info: 1,
      treeList: [],
      checkedCities: [],
      timestampToTime1,
      projectP: 0,
      currentId: "",
      currentPage2: 1,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
        type: "type"
      },
      hide: 1,
      modal: 0,
      total: 0,
      // videoData: [{ online: 1, id: 111 }, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      videoData: [],
      permissoin: ["get"],
      searchForm: {},
      netArrUpdate: [{ id: 0 }],
      ruleForm: {
        pwd: 123456
      },
      //当前播放视屏监控点号
      currentCIColde: [],
      cameraindexcode: "",
      //当前播放视屏序列
      currentSequence: 0,
      cname: "组织名称",
      orgName: "",
      //定时器
      timeOut: 0,
      ztstate: 0,
      popup: 0,
      //插件对象
      oWebControl: null
    };
  },
  methods: {
    //播放已选
    playChecked() {
      // let _this = this
      let arr = this.$refs.Tree.getCheckedNodes();
      var str = "";
      arr.forEach(item => {
        if (item.type == 4) {
          str += item.monitorNo + ",";
        }
      });
      str = str.substr(0, str.length - 1);

      if (str == "") {
        this.$message.error("请选择需要播放的视屏");
      } else {       
      //  if (this.oWebControl != null) {
      //    this.currentCIColde = str.split(",");
      //    this.currentSequence = 0;
      //   _this.stopAll()
      // }else{
      //   this.videoShow(str);
      // }
       this.videoShow(str);
        
      }
    },
    //播放视频
    videoShow(cameraindexcode) {
      // if (this.oWebControl != null) {
      //   this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      //   this.oWebControl.JS_Disconnect().then();
      // }
      this.currentCIColde = cameraindexcode.split(",");
      if (this.currentCIColde.length > 24) {
        this.$message("最多可播放24条视频");
      } else {   
        this.currentSequence = 0;
        // this.initPlugin();
        this.stopAll()
      }
    },
    hideChange(i) {
      this.hide = i;
      let _this = this;
      // if (this.oWebControl != null) {
      //   this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      //   this.oWebControl.JS_Disconnect().then(
      //     ()=>{
      //        _this.currentSequence = 0;
      //   _this.initPlugin();
      //     }
      //   );
      // }
      _this.oWebControl.JS_Resize(
              _this.$refs.hk.offsetWidth,
              _this.$refs.hk.offsetHeight - 50
            );
    },
    close() {
      this.hide = 1;
      // 标签关闭
      if (this.oWebControl != null) {
        this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        this.oWebControl.JS_Disconnect().then(function() {}, function() {});
      }
    },
    stopAll() {
      let _this = this;
      this.oWebControl
        .JS_RequestInterface({
          funcName: "stopAllPreview"
        }).then(() => {_this.startPreview();});
    },
    //获取视频状态
    getState() {
      let data = { orgId: this.currentId };
      getTotal(data).then(res => {
        if (res.code == 200) {
          this.form.projectCount = res.data.projectCount;
          this.form.onlineCount = res.data.onlineCount;
          this.$forceUpdate();
        }
      });
    },
    //初始化
    init() {
      console.log('重新初始化',this.currentlayout);
      
      var _this = this;
      _this.getPubKey(function() {
        var appkey = "26177308";
        var secret = "3u7SqzJ73wmfHnMdRKnW";
        var ip = "118.123.244.181";
        var szPort = "443";
        var snapDir = "D:SnapDir";
        var videoDir = "D:VideoDir";
        var layout = _this.currentlayout;
        // var encryptedFields = ["secret"];
        var szShowToolbar = "1";
        var szShowSmart = "1";
        var btIds = "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769";

        appkey = appkey.replace(/(^\s*)/g, "");
        appkey = appkey.replace(/(\s*$)/g, "");

        secret = secret.replace(/(^\s*)/g, "");
        secret = secret.replace(/(\s*$)/g, "");

        ip = ip.replace(/(^\s*)/g, "");
        ip = ip.replace(/(\s*$)/g, "");

        szPort = szPort.replace(/(^\s*)/g, "");
        szPort = szPort.replace(/(\s*$)/g, "");

        snapDir = snapDir.replace(/(^\s*)/g, "");
        snapDir = snapDir.replace(/(\s*$)/g, "");

        videoDir = videoDir.replace(/(^\s*)/g, "");
        videoDir = videoDir.replace(/(\s*$)/g, "");

        var port = parseInt(szPort);
        var showSmart = parseInt(szShowSmart);
        var showToolbar = parseInt(szShowToolbar);
        var enableHttps = 1;

        // encryptedFields = "secret";
        console.log({
          appkey: appkey,
          secret: secret,
          ip: ip,
          playMode: 0, // 预览
          port: port,
          snapDir: snapDir,
          videoDir: videoDir,
          layout: layout,
          enableHTTPS: enableHttps,
          showToolbar: showToolbar,
          showSmart: showSmart,
          buttonIDs: btIds
          // encryptedFields: encryptedFields,
        });
        _this.oWebControl
          .JS_RequestInterface({
            funcName: "init",
            argument: JSON.stringify({
              appkey: appkey,
              secret: secret,
              ip: ip,
              playMode: 0, // 预览
              port: port,
              snapDir: snapDir,
              videoDir: videoDir,
              layout: layout,
              enableHTTPS: enableHttps,
              showToolbar: showToolbar,
              showSmart: showSmart,
              buttonIDs: btIds
              // encryptedFields: encryptedFields,
            })
          })
          .then(function(oData) {
            _this.oWebControl.JS_Resize(
              _this.$refs.hk.offsetWidth,
              _this.$refs.hk.offsetHeight - 50
            ); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            _this.startPreview();
            console.log("初始化", oData);
          });
      });
    },
    // 获取公钥
    getPubKey(callback) {
      var _this = this;
      _this.oWebControl
        .JS_RequestInterface({
          funcName: "getRSAPubKey",
          argument: JSON.stringify({
            keyLength: 1024
          })
        })
        .then(function(oData) {
          if (oData.responseMsg.data) {
            _this.pubKey = oData.responseMsg.data;
            callback();
          }
        });
    },
    //视屏预览
    startPreview() { 
      var _this = this;
      //监控点编号
      // var cameraIndexCode = "185dad0787e94fefad7dc8df57da33ba";
      var cameraIndexCode = _this.currentCIColde[this.currentSequence];
      var streamMode = 0;
      var transMode = 1;
      var gpuMode = 0;
      var wndId = _this.currentSequence+1; //默认为空闲窗口回放
      cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, "");
      cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, "");
      this.oWebControl
        .JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,
            streamMode: streamMode,
            transMode: transMode,
            gpuMode: gpuMode,
            wndId: wndId
          })
        })
        .then(function(oData) {
          _this.currentSequence++;
          console.log(oData);
          if (_this.currentSequence < _this.currentCIColde.length) {
            _this.timeOut = setTimeout(function() {
              _this.startPreview();
            }, 1000);
          } else if (_this.currentSequence == 25) {
            clearTimeout(_this.timeOut);
          } else {
            clearTimeout(_this.timeOut);
          }
        });
    },
    //回调函数
    cbIntegrationCallBack(oData) {
      var msg = JSON.stringify(oData.responseMsg.msg);
      msg = msg.substring(1, msg.length - 1);
      var msgArray = msg.split(",");
      var cameraIndex = msgArray[0];
      if (msgArray[0].length > 25) {
        var array = cameraIndex.split(":");
        this.currentCIColdeCJ = array[1].substring(2, array[1].length - 2);
      }
      // showCBInfo(JSON.stringify(oData.responseMsg));
    },
    // 初始化插件
    initPlugin() {
      var _this = this;
      var oWebControl = new WebControl({
        szPluginContainer: "playWnd",
        iServicePortStart: 15900,
        iServicePortEnd: 15909,
        szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11", // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function() {
          _this.oWebControl.JS_SetWindowControlCallback({
            cbIntegrationCallBack: _this.cbIntegrationCallBack
          });
          oWebControl
            .JS_StartService("window", {
              dllPath: "./VideoPluginConnect.dll"
            })
            .then(function() {
              oWebControl.JS_CreateWnd("playWnd", 1183, 665).then(function() {
                _this.init();
              });
            });
        },
        cbConnectError: function() {
          console.log("cbConnectError");
          _this.oWebControl = null;
          _this.$message("插件未启动，正在尝试启动，请稍候...");
          WebControl.JS_WakeUp("VideoWebPlugin://");
          let initCount = 0;
          initCount++;
          if (initCount < 3) {
            setTimeout(function() {
              _this.initPlugin();
            }, 3000);
          } else {
            _this.$message("插件启动失败，请检查插件是否安装！");
          }
        },
        cbConnectClose: function(bNormalClose) {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log(bNormalClose);
          _this.oWebControl = null;
        }
      });
      this.oWebControl = oWebControl;
    }
  },
  mounted() {
    //获取权限
    this.permissoin = [];
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach(item => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    orgAndVideo().then(res => {
      if (res.code == 200) {
        this.getState();
        this.treeData = res.data;
      } else {
        this.treeData = [];
      }
    });
    this.currentCIColde=['','','','']
     this.initPlugin();
    // this.$refs.videolist.style.height =
    //   this.$refs.index_main.offsetHeight - 350 + "px";
    //   this.$refs.company_main.style.height =
    //   this.$refs.index_main.offsetHeight*0.806-10 + "px";
  },
  destroyed() {
    // 标签关闭
    if (this.oWebControl != null) {
      this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      this.oWebControl.JS_Disconnect().then();
    }
    clearTimeout(this.timeOut);
  },
  computed: {
    currentlayout: function() {
      switch (this.currentCIColde.length) {
        case 1:
          return "1x1";
        case 2:
          return "1x2";
        case 3:
          return "1+2";
        case 4:
          return "2x2";
        case 5:
          return "1+5";
        case 6:
          return "1+5";
        case 7:
          return "1+7";
        case 8:
          return "1+7";
        case 9:
          return "3x3";
        case 10:
          return "1+9";
        case 11:
          return "4+9";
        case 12:
          return "4+9";
        case 13:
          return "4+9";
        case 14:
          return "4x4";
        case 15:
          return "4x4";
        case 16:
          return "4x4";
        case 17:
          return "4x6";
        case 18:
          return "4x6";
        case 19:
          return "4x6";
        case 20:
          return "4x6";
        case 21:
          return "4x6";
        case 22:
          return "4x6";
        case 23:
          return "4x6";
        case 24:
          return "4x6";
        default:
          return "4x6";
      }
    }
  }
};
</script>

<style lang="less" >
.Video {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;

  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  aside {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 300px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      height: 720px;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
        border-bottom: 1px dashed #ebf0f5;
        font-size: 12px;
        padding-left: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .active {
        color: #466cd4;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-around;
      color: #595959;
      font-size: 14px;
      padding-bottom: 18px;
      p {
        line-height: 24px;
        text-align: center;
      }
      .green_c {
        border-radius: 50%;
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid #24bd49;
        margin-right: 4px;
        position: relative;
        top: 2px;
      }
      .orange_c {
        border-radius: 50%;
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 2px solid #f08207;
        margin-right: 4px;
        position: relative;
        top: 2px;
      }
    }
  }
  .hk {
    flex: 1;
    height: 100%;
    .title {
      display: flex;
      justify-content: space-between;
      line-height: 50px;
      background-color: #fff;
      .blue {
        line-height: 50px;
        margin-left: 16px;
        font-size: 14px;
        .weight {
          font-weight: 600;
        }
        i {
          font-size: 22px;
          color: #ff6400;
          margin-right: 10px;
          position: relative;
          top: 3px;
        }
      }
      .btns {
        margin-right: 20px;
        .btn_r {
          width: 100px;
          line-height: 30px;
          border-radius: 8px;
          text-align: center;
          color: #fff;
          background-color: #fe0000;
          text-align: center;
          margin-right: 18px;
          cursor: pointer;
        }
        .btn_g {
          width: 100px;
          line-height: 30px;
          border-radius: 8px;
          text-align: center;
          color: #fff;
          background-color: #16bb9b;
          text-align: center;
          margin-right: 22px;
          cursor: pointer;
        }
        .btn_gray {
          width: 100px;
          line-height: 30px;
          border-radius: 8px;
          text-align: center;
          color: #fff;
          background-color: #3d3d3d;
          text-align: center;
          margin-right: 18px;
          cursor: pointer;
        }
        .btn_b {
          width: 100px;
          line-height: 30px;
          border-radius: 8px;
          text-align: center;
          color: #fff;
          background-color: #0090ff;
          text-align: center;
          margin-right: 18px;
          cursor: pointer;
        }
      }
      .el-icon-close {
        cursor: pointer;
        font-size: 18px;
        margin-right: 22px;
        vertical-align: middle;
      }
    }
  }
  .index_right {
    flex: 1;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 28px;
    box-sizing: border-box;
    .title {
      margin-bottom: 22px;
      img {
        margin-right: 6px;
      }
    }
    .header {
      height: 190px;
      display: flex;
      .head_left {
        width: 190px;
        height: 190px;
      }
      .head_right {
        font-size: 12px;
        display: flex;
        .item1 {
          margin-left: 100px;
        }
        p {
          line-height: 42px;
          & > span:nth-child(1) {
            display: inline-block;
            width: 90px;
          }
        }
      }
    }
    .videotitle {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 40px;
      img {
        margin-left: 6px;
      }
    }
    .videolist {
      height: 547px;
      padding-left: 20px;
      box-sizing: border-box;
      .el-checkbox-group {
        width: 100%;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0px;
        }
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-items: flex-start;
        font-size: 12px;
        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #4e5255;
        }
        .el-checkbox__label {
          font-size: 12px;
        }
        .video_item {
          width: 290px;
          height: 242px;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 6px;
          .imgwarpper {
            cursor: pointer;
            width: 290px;
            height: 170px;
            position: relative;
            border-radius: 6px 6px 0 0;
            .img1 {
              width: 100%;
              height: 100%;
              border-radius: 6px 6px 0 0;
              cursor: pointer;
            }
            .img2 {
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 6px 6px 0 0;
              top: 0px;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.6);
            }
          }
          .vi_bottom {
            display: flex;
            justify-content: space-between;
            padding: 16px 20px;
            box-sizing: border-box;
            width: 290px;
            height: 72px;
            border: 1px solid #e4e4e4;
            border-radius: 0 0 6px 6px;
            .address_left {
              width: 172px;
              display: inline-block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              i {
                color: #7650f1;
                margin-right: 5px;
              }
            }
            .vib_left {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              img {
                position: relative;
                top: 3px;
                margin-right: 8px;
                border-radius: 0 0 6px 6px;
              }
            }
            .vib_green {
              font-size: 10px;
              line-height: 14px;
              padding: 0 4px;
              border-radius: 4px;
              background-color: #00963a;
              color: #fff;
              margin-right: 8px;
            }
            .vib_gray {
              font-size: 10px;
              line-height: 14px;
              padding: 0 4px;
              border-radius: 4px;
              background-color: #b9b9b9;
              color: #fff;
              margin-right: 8px;
            }
          }
        }
      }
    }

    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
}
</style>